import { PureComponent } from 'react'

import { css } from '@emotion/react'
import styled from '@emotion/styled'
import Link from 'next/link'

import { WWW_BASE_URL } from '@helpers/constants'
import { generateImageUrl } from '@helpers/get-cloudflare-img-url'
import AutoComplete from 'components/autocomplete'
import Colors from 'microcomponents/colors'
import Logo from 'microcomponents/icons/logo'
import { Title } from 'microcomponents/typography'

import { bool, func, object, string } from 'prop-types'

import rebrandTheme from '../rebrand-address-theme.module.scss'

export default class LocationForm extends PureComponent {
  static propTypes = {
    activeLocation: object,
    addressLoading: bool,
    inSampleLocation: bool,
    isLoggedIn: bool,
    onLocationChange: func,
    residentialOnlyError: bool,
    formLocation: string
  }

  static defaultProps = {
    activeLocation: {}
  }

  handleAutocompleteSubmit = (suggestion) => {
    const { onLocationChange } = this.props
    onLocationChange(suggestion)
  }

  render() {
    const { addressLoading, activeLocation, inSampleLocation, isLoggedIn, residentialOnlyError, formLocation } =
      this.props

    return (
      <Container isLoggedIn={isLoggedIn} formLocation={formLocation}>
        {formLocation === 'header' && <StyledLogo width={6.3} fill="white" />}
        <StyledTitle formLocation={formLocation}>
          {formLocation === 'header' ? 'HIGHLY CALCULATED CANNABIS DELIVERY' : 'GET YOUR WEED TODAY'}
        </StyledTitle>
        <AutoComplete
          addressLoading={addressLoading}
          value={activeLocation.title && activeLocation.title}
          onSubmitSuggestion={this.handleAutocompleteSubmit}
          inSampleLocation={inSampleLocation}
          theme={rebrandTheme}
          containerStyle={autocompleteContainerStyle}
          residentialOnlyError={residentialOnlyError}
          buttonCss={buttonCss}
          formLocation={formLocation}
        />

        {!isLoggedIn && formLocation === 'header' && (
          <AccountActions formLocation={formLocation}>
            <Link href="/login">
              <LoginLink>LOGIN</LoginLink>
            </Link>
            <Link href="/signup">
              <SignupLink>SIGN UP</SignupLink>
            </Link>
          </AccountActions>
        )}
      </Container>
    )
  }
}

const colorMap = {
  black: Colors.homepageRedesign[0],
  yellow: Colors.homepageRedesign[1],
  white: Colors.homepageRedesign[2],
  blue: Colors.homepageRedesign[5],
  red: Colors.homepageRedesign[6]
}

const StyledTitle = styled(Title)`
  color: ${colorMap.white};
  font-size: ${({ formLocation }) => (formLocation === 'footer' ? '16px' : '1.7rem')};
  line-height: 1.7;
  margin: ${({ formLocation }) => (formLocation === 'footer' ? '40px' : '0')};
  font-weight: 800;
  letter-spacing: 2px;
  text-align: center;
  text-transform: uppercase;
  font-family: 'Suisse Intl Mono Bold', monospace;
  @media (max-width: 767px) {
    font-size: 1.4rem;
    width: ${({ formLocation }) => (formLocation === 'footer' ? '300px' : '20rem')};
    margin-bottom: ${({ formLocation }) => formLocation !== 'footer' && '20px'};
    margin: ${({ formLocation }) => formLocation === 'footer' && '1.9rem 0 0'};
  }
`

const StyledLogo = styled(Logo)`
  @media (max-width: 767px) {
    display: none;
  }
`

const buttonCss = () => css`
  color: ${colorMap.black};
  background-color: ${colorMap.yellow};
  font-family: Gilroy;
  font-style: italic;
  font-weight: 700;
  font-size: 1.4rem;
  height: 4rem;
  border-radius: 0;
  width: auto;
  padding: 1rem 1.5rem;
  @media (max-width: 767px) {
    margin-top: 1.6rem;
  }
`
const autocompleteContainerStyle = css`
  border-radius: 0;
  background: ${colorMap.red};
`

const desktopPub2x = `url("${generateImageUrl(`${WWW_BASE_URL}/static/homepage/desktop-pub@2x.png`, {
  width: 2000,
  format: 'auto '
})}")`
const desktopPub3x = `url("${generateImageUrl(`${WWW_BASE_URL}/static/homepage/desktop-pub@3x.png`, {
  width: 3000,
  format: 'auto '
})}")`

const Container = styled.div`
  background: ${colorMap.blue} ${({ formLocation }) => (formLocation === 'footer' ? 'none' : desktopPub2x)} no-repeat;
  @media (min-width: 2048px) {
    background-image: ${({ formLocation }) => (formLocation === 'footer' ? 'none' : desktopPub3x)};
  }
  @media (min-device-pixel-ratio: 2), (min-resolution: 2dppx) {
    @media (min-width: 1536px) {
      background-image: ${({ formLocation }) => (formLocation === 'footer' ? 'none' : desktopPub3x)};
    }
  }
  @media (min-device-pixel-ratio: 3), (min-resolution: 3dppx) {
    background-image: ${({ formLocation }) => (formLocation === 'footer' ? 'none' : desktopPub3x)};
  }
  background-size: cover;
  background-position: center 70%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  padding-top: ${({ formLocation }) => formLocation !== 'footer' && '6rem'};

  @media (max-width: 767px) {
    background: ${colorMap.blue};
    height: ${({ formLocation }) => formLocation === 'footer' && '28rem'};
    justify-content: ${({ formLocation }) => formLocation === 'footer' && 'space-evenly'};
  }

  @media (min-width: 768px) {
    padding-top: ${({ formLocation }) => formLocation === 'footer' && '2.5rem'};
    padding-bottom: 12.6rem;
    height: ${({ formLocation }) => (formLocation === 'footer' ? '23rem' : '35rem')};
  }
`

const AccountActions = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 3rem;
  margin-top: 25px;
  @media (min-width: 768px) {
    display: none;
  }
`

const LoginLink = styled.a`
  color: ${colorMap.white};
  display: block;
  font-family: 'Suisse Intl Book', system-ui, sans-serif;
  font-size: 1rem;
  padding: 0.25rem 0.5rem;
  text-align: center;
  text-transform: uppercase;
  margin: 0 5px;
`

const SignupLink = styled(LoginLink)`
  border: 1px solid ${colorMap.white};
`
